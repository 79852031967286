import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { fetchApi } from "../Helper/Fetch"
import Animator from "../Components/Animator"
import { ReactComponent as Sprite1 } from "../Svgs/Sprites/sprite1.svg";
import { ReactComponent as Sprite2 } from "../Svgs/Sprites/sprite2.svg";
import { ReactComponent as Sprite3 } from "../Svgs/Sprites/sprite3.svg";
import { ReactComponent as Sprite4 } from "../Svgs/Sprites/sprite4.svg";
import { ReactComponent as Sprite5 } from "../Svgs/Sprites/sprite5.svg";


const Projects = () => {

  const [projects, setProjects] = useState([]);
  const [tags, setTags] = useState([]);
  const [filtered, setFiltered] = useState(projects);

  useEffect(() => {
    fetchApi("lakmuhely", setProjects);
    return () => {
      setProjects()
    }
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setTags(query.get('tags'))
  }, [projects])


  useEffect(() => {
    const filterPlaces = () => {
      return tags && projects ? projects.filter(temp => temp.tags.includes(tags)) : projects
    }
    setFiltered(filterPlaces())
  }, [projects, tags])

  return (
    <div className="pro">
      {filtered.map((project) => (
        <NavLink key={project._id} exact to={"projekt/" + project.title_slug} className="card">
          <Animator
            bg={"https://innruptive.com/api/storage/uploads" + project.thumbnail.path}
            sprite={(() => {
              switch (project.seq) {
                case "1":
                  return <Sprite1 />
                case "2":
                  return <Sprite2 />
                case "3":
                  return <Sprite3 />
                case "4":
                  return <Sprite4 />
                case "5":
                  return <Sprite5 />
                default:
                  return <Sprite3 />
              }
            })()}
            pathId={(() => {
              switch (project.seq) {
                case "1":
                  return "#mask1_feher_png"
                case "2":
                  return "#mask2_feher_png"
                case "3":
                  return "#mask3_feher_png"
                case "4":
                  return "#mask4_feher_png"
                case "5":
                  return "#mask5_feher_png"
                default:
                  return "#mask1_feher_png"
              }
            })()}
          />
        </NavLink>
      ))}
    </div>
  );
};

export default Projects;
import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';

const Project = ({ entries }) => {
    const article = useRef(null);
    const history = useHistory();
    const currUrl = useState(history.location.pathname.split('/').pop())

    return (
        <>
            <section ref={article} className="article">
                {entries.filter(entry => entry.title_slug.includes(currUrl[0])).map(post => (
                    <div className="row" key={post._id}>
                        <Helmet>

                            <meta name="description" content={post.excerpt} />
                            <meta name="keywords" content={post.meta_keywords} />

                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={"https://lakmuhely.hu/projekt/" + post.title_slug} />
                            <meta property="og:title" content={"Lakműhely - " + post.title} />
                            <meta property="og:description" content={post.excerpt} />
                            <meta property="og:image" content={"https://innruptive.com/api/storage/uploads" + post.image.path} />

                            <meta property="twitter:card" content={"https://innruptive.com/api/storage/uploads" + post.image.path} />
                            <meta property="twitter:url" content={"https://lakmuhely.hu/project/" + post.title_slug} />
                            <meta property="twitter:title" content={"Innruptive - " + post.title} />
                            <meta property="twitter:description" content={post.excerpt} />
                            <meta property="twitter:image" content={"https://innruptive.com/api/storage/uploads" + post.image.path} />

                        </Helmet>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 img-cont" >
                            <img src={"https://innruptive.com/api/storage/uploads" + post.image.path} alt="" />
                            {post.gallery ? post.gallery.map(imgs => <img src={"https://innruptive.com" + imgs.path} alt="" />) : ""}
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <h1>{post.title}</h1>
                            <p className="excerpt" dangerouslySetInnerHTML={{
                                __html: post.excerpt
                            }} />
                            <div className="text" dangerouslySetInnerHTML={{
                                __html: post.content
                            }} >
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </>
    );
};

export default Project;
import React, { useState, useEffect } from "react";

const Animator = (props) => {

    const [count, setCounter] = useState(0);
    const [isHover, setIsHovered] = useState("initial");

    useEffect(() => {
        if (isHover === true && count < 35) {
            setTimeout(() => setCounter(count + 1), 36)
        } if (count !== 0 && isHover === false) {
            setTimeout(() => setCounter(count - 1), 36)
        }
    }, [isHover, count]);

    return (
        <>
            {props.sprite}
            <div className="svg-wrapper" style={{backgroundImage: `url(${props.bg})`}} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                <svg className="icon">
                    <use xlinkHref={props.pathId + count}></use>
                </svg>
            </div>
        </>
    );
};

export default Animator;
import React from "react";

const Legal = () => {

    return (
        <section className="article two">
            <div className="row">
                <div className="col-md-12">
                    <div className="text">
                        <h1>Tanácsadási szolgáltatások</h1>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="text">
                        <h3>Építészeti szaktanácsadás</h3>
                        Az építési telek kiválasztásával, az építési telek alkalmasságával kapcsolatos szaktanácsadás, szakmai vélemény, javaslattétel. <br />
                        Meglévő épület felújításával, korszerűsítésével, átalakításával illetve bővítésével kapcsolatos kérdésekkel kapcsolatos konzultáció. <br />
                        Tervezői költségbecslés készítése.
                        <br /><br />
                        <h3>Épületenergetikai szaktanácsadás
                        </h3>
                        A tervezett épület vonatkozásában energia-megtakarításra irányuló javaslattétel, amely reális megvalósíthatóságra, illetve az épület rendeltetését és műszaki állapotát is figyelembe véve, mindenkor a költséghatékony megoldásra koncentrálnak.
                        <br /><br />
                        <h3>Épületvillamossági szaktanácsadás</h3>
                        Új vagy meglévő épület vonatkozásában épületvillamossági szaktanácsadás. A tervezett épület esetén elektromos berendezések alaprajzon való megjelenítése.
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12">
                    <div className="text">
                        <h3>Épületgépészeti szaktanácsadás</h3>
                        Új vagy meglévő épület vonatkozásában épületgépészeti (vízellátás és csatornázás, gázellátás, fűtés, szellőzés, légtechnika, klíma és hűtéstechnika) szaktanácsadás. A tervezett épület esetén a gépészeti berendezések alaprajzon való megjelenítése.
                        <h3>Ingatlan szaktanácsadás</h3>
                        Adott ingatlan vásárlási szándék esetén szemrevételezéses épületdiagnosztikai vizsgálata alapján az épület műszaki állapotának meghatározása. Szaktanácsadás annak megállapítására, hogy a megvásárolni szándékozott ingatlan kínalati ára és a műszaki állapota arányban áll-e egymással. Segítséget nyújtunk abban, hogy az adott ingatlan az elképzelt célra műszakilag és a vonatkozó jogszabályok alapján alkalmas-e, illetve milyen műszaki és pénzügyi feltételekkel tehető alkalmassá az elképzelt rendeltetésre vagy a tervezett funkcióra. Vállaljuk egyes ingatlanok rendeltetés változásának ügyintézését is.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Legal;
import React from "react";

import HuMap from "../Components/HuMap"

const About = () => {

  return (
    <section className="article">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 img-cont">
          <HuMap />
        </div>
      </div>
    </section>
  );
};

export default About;
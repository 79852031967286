import React from 'react'
import { NavLink } from "react-router-dom";

const HuMap = () => {

    return (
        <table className="map">
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                </tr>
                <tr>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu ott"><NavLink exact to="/projektek?tags=Debrecen"></NavLink></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu ott"><NavLink exact to="/projektek?tags=Hajdúszoboszló"></NavLink></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td className="hu"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>

    );
};

export default HuMap;

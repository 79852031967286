import React from "react";

const Design = () => {

  return (
    <section className="article">
      <div className="row">
        <div className="col-md-8 col-sm-12 col-xs-12 img-cont">
          <img src="https://innruptive.com/api/storage/uploads/2021/05/19/60a52fd19aaa4Rectangle-36.png" alt="" />
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12">
          <h1>Építészeti tervezés</h1>
          <div className="text">
            Tudjuk, hogy a legjobb épületek mindig a megbízóinkkal való őszinte és nyitott kommunikáción alapuló együttműködésből születnek. <br />
            Az ügyfeleink elképzeléseiből táplálkozva erős, egyedi karaktert adunk épületeink számára. Minden projektet egy történet köré építünk fel, mely inspirálja az építészeti koncepciót és vezérfonalként kíséri végig munkánkat és ruházza fel egyéniséggel épületeinket. <br />
            Tervezéskor az arányosságra koncentrálunk, hogy az alkotásainkban a gazdasági, ergonómiai, komfort hármas értékeket egyensúlyban tartsuk. Olyan eszközöket használunk, amely pozitív hatást gyakorol a közösség és az egyén életében, illeszkedik környezetéhez és rezonál a felhasználójával.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Design;
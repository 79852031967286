import React from 'react';
import ReactDOM from 'react-dom';
import './app.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga';
ReactGA.initialize('G-3W27Q4TBS0');

// Console Hi from Innruptive

const green = [
  'background: #000'
  , 'color: springgreen'
  , 'display: inline-block'
  , 'font-weight: bold'
].join(';');

console.log('%c innruptive.com', green);
console.log('%c', green);


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

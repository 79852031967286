import React from "react";

const NotFound = () => {

  return (
    <>
      <p>
        404
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam quos recusandae deleniti necessitatibus nulla nam debitis, id consectetur? Dolorem, fugit beatae ipsa pariatur nobis incidunt ea praesentium ut minus ad?
    </p>
    </>
  );
};

export default NotFound;
import React, { useState, useEffect } from "react";
import Router from "./Router";
import { useLocation } from "react-router-dom";

import './app.scss'
import { ReactComponent as Emblem } from "./Svgs/3d_emblema.svg";
import Nav from "./Components/Nav";
import { Facebook, Instagram } from "./Components/svgs"
import ReactGA from 'react-ga';

function App() {

  const [currLoc, setCurrLoc] = useState("");

  let location = useLocation()

  useEffect(() => {
    let tempLoc = location?.pathname.split("/")
    setCurrLoc(tempLoc[1] === "" ? "main" : tempLoc[1])
		ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);


  return (
    <>
      <Nav />
      <div className={"content " + currLoc}>
        <Router />
      </div>
      <footer className="footer">
        <div className="wrap">
        <div className="social">
                    <a target="_blank" href="https://www.facebook.com/LAKm%C5%B1hely-115154350222592" rel="noreferrer" title="facebook">
                        <Facebook fill="cacaca" className="facebook" />
                    </a>
                    <a target="_blank" href="https://www.instagram.com/lakmuhely/" rel="noreferrer" title="instagram">
                        <Instagram fill="cacaca" className="instagram" />
                    </a>
                </div>
          <p>©<span>lakműhely építész stúdió 2021</span></p>
          <Emblem />
        </div>
      </footer>
    </>
  )
}

export default App

import React from "react";

export const Facebook = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={props.fill}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.76 0H0.98C0.44 0 0 0.44 0 0.98V16.76C0 17.3 0.44 17.74 0.98 17.74H9.47V10.87H7.16V8.19H9.47V6.22C9.47 3.93 10.87 2.68 12.91 2.68C13.6 2.68 14.29 2.71 14.98 2.78V5.18H13.56C12.44 5.18 12.23 5.71 12.23 6.48V8.19H14.88L14.53 10.87H12.22V17.74H16.74C17.28 17.74 17.72 17.3 17.72 16.76V0.98C17.74 0.44 17.3 0 16.76 0Z"
            fill="black"
        />
    </svg>
);

export const Instagram = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={props.fill}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.86999 5.26001C6.87999 5.26001 5.25999 6.87001 5.25999 8.87001C5.25999 10.87 6.86999 12.48 8.86999 12.48C10.86 12.48 12.48 10.87 12.48 8.87001C12.47 6.87001 10.86 5.26001 8.86999 5.26001Z" fill="black" />
        <path d="M17.68 4.5C17.67 3.81 17.55 3.13 17.31 2.49C16.94 1.55 16.2 0.81 15.26 0.44C14.62 0.2 13.94 0.07 13.25 0.07C12.1 0 11.76 0 8.87 0C5.98 0 5.64 0.01 4.5 0.06C3.81 0.07 3.13 0.2 2.49 0.44C1.55 0.8 0.8 1.55 0.44 2.49C0.2 3.13 0.07 3.81 0.06 4.5C0 5.64 0 5.98 0 8.87C0 11.76 0.01 12.1 0.06 13.24C0.07 13.93 0.19 14.61 0.43 15.25C0.8 16.19 1.54 16.93 2.48 17.3C3.12 17.54 3.8 17.67 4.49 17.67C5.63 17.73 5.97 17.73 8.86 17.73C11.75 17.73 12.09 17.72 13.23 17.67C13.92 17.66 14.6 17.54 15.24 17.3C16.18 16.93 16.92 16.19 17.29 15.25C17.53 14.61 17.66 13.93 17.66 13.24C17.72 12.1 17.72 11.75 17.72 8.87C17.72 5.99 17.73 5.64 17.68 4.5ZM14.43 8.87C14.43 11.94 11.94 14.43 8.87 14.43C5.8 14.43 3.31 11.94 3.31 8.87C3.31 5.8 5.8 3.31 8.87 3.31C11.94 3.31 14.43 5.8 14.43 8.87ZM14.64 4.39C13.92 4.39 13.34 3.81 13.34 3.09C13.34 2.37 13.92 1.79 14.64 1.79C15.36 1.79 15.94 2.37 15.94 3.09C15.94 3.81 15.36 4.39 14.64 4.39Z" fill="black" />
    </svg>

);

export const MapSm = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={props.fill}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.86999 5.26001C6.87999 5.26001 5.25999 6.87001 5.25999 8.87001C5.25999 10.87 6.86999 12.48 8.86999 12.48C10.86 12.48 12.48 10.87 12.48 8.87001C12.47 6.87001 10.86 5.26001 8.86999 5.26001Z" fill="black" />
        <path d="M17.68 4.5C17.67 3.81 17.55 3.13 17.31 2.49C16.94 1.55 16.2 0.81 15.26 0.44C14.62 0.2 13.94 0.07 13.25 0.07C12.1 0 11.76 0 8.87 0C5.98 0 5.64 0.01 4.5 0.06C3.81 0.07 3.13 0.2 2.49 0.44C1.55 0.8 0.8 1.55 0.44 2.49C0.2 3.13 0.07 3.81 0.06 4.5C0 5.64 0 5.98 0 8.87C0 11.76 0.01 12.1 0.06 13.24C0.07 13.93 0.19 14.61 0.43 15.25C0.8 16.19 1.54 16.93 2.48 17.3C3.12 17.54 3.8 17.67 4.49 17.67C5.63 17.73 5.97 17.73 8.86 17.73C11.75 17.73 12.09 17.72 13.23 17.67C13.92 17.66 14.6 17.54 15.24 17.3C16.18 16.93 16.92 16.19 17.29 15.25C17.53 14.61 17.66 13.93 17.66 13.24C17.72 12.1 17.72 11.75 17.72 8.87C17.72 5.99 17.73 5.64 17.68 4.5ZM14.43 8.87C14.43 11.94 11.94 14.43 8.87 14.43C5.8 14.43 3.31 11.94 3.31 8.87C3.31 5.8 5.8 3.31 8.87 3.31C11.94 3.31 14.43 5.8 14.43 8.87ZM14.64 4.39C13.92 4.39 13.34 3.81 13.34 3.09C13.34 2.37 13.92 1.79 14.64 1.79C15.36 1.79 15.94 2.37 15.94 3.09C15.94 3.81 15.36 4.39 14.64 4.39Z" fill="black" />
    </svg>

);

export const MapBg = (props) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={props.fill}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8.86999 5.26001C6.87999 5.26001 5.25999 6.87001 5.25999 8.87001C5.25999 10.87 6.86999 12.48 8.86999 12.48C10.86 12.48 12.48 10.87 12.48 8.87001C12.47 6.87001 10.86 5.26001 8.86999 5.26001Z" fill="black" />
        <path d="M17.68 4.5C17.67 3.81 17.55 3.13 17.31 2.49C16.94 1.55 16.2 0.81 15.26 0.44C14.62 0.2 13.94 0.07 13.25 0.07C12.1 0 11.76 0 8.87 0C5.98 0 5.64 0.01 4.5 0.06C3.81 0.07 3.13 0.2 2.49 0.44C1.55 0.8 0.8 1.55 0.44 2.49C0.2 3.13 0.07 3.81 0.06 4.5C0 5.64 0 5.98 0 8.87C0 11.76 0.01 12.1 0.06 13.24C0.07 13.93 0.19 14.61 0.43 15.25C0.8 16.19 1.54 16.93 2.48 17.3C3.12 17.54 3.8 17.67 4.49 17.67C5.63 17.73 5.97 17.73 8.86 17.73C11.75 17.73 12.09 17.72 13.23 17.67C13.92 17.66 14.6 17.54 15.24 17.3C16.18 16.93 16.92 16.19 17.29 15.25C17.53 14.61 17.66 13.93 17.66 13.24C17.72 12.1 17.72 11.75 17.72 8.87C17.72 5.99 17.73 5.64 17.68 4.5ZM14.43 8.87C14.43 11.94 11.94 14.43 8.87 14.43C5.8 14.43 3.31 11.94 3.31 8.87C3.31 5.8 5.8 3.31 8.87 3.31C11.94 3.31 14.43 5.8 14.43 8.87ZM14.64 4.39C13.92 4.39 13.34 3.81 13.34 3.09C13.34 2.37 13.92 1.79 14.64 1.79C15.36 1.79 15.94 2.37 15.94 3.09C15.94 3.81 15.36 4.39 14.64 4.39Z" fill="black" />
    </svg>

);
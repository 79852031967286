import React from "react";
import v720p from "../Assets/lakmuhely_landing_video4_720p.mp4";
import v1024p from "../Assets/lakmuhely_landing_video4_1080p.mp4";

const Intro = () => {
  return (
    <div className="main">
      <video muted controls autoPlay playsInline loop preload="auto">
        <source src={v720p} type="video/mp4" media="all and (max-width:1024px)" />
        <source src={v1024p} type="video/mp4" media="all and (min-width:1025px)" />
      </video>
    </div>
  );
};

export default Intro;
import React from "react";

const Interior = () => {

    return (
        <section className="article">
            <div className="row">
                <div className="col-md-8 col-sm-12 col-xs-12 img-cont">
                    <img src="https://innruptive.com/api/storage/uploads/2021/09/14/6140739c656a1interior.png" alt="Belsőépítészet" />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <h1>Belsőépítészet</h1>
                    <div className="text">
                    Terveinket a kezdeti stádiumtól kezdve bútorozott alaprajzzal mutatjuk meg a konzultációk során. Az építészet és a belsőépítészeti tervezés párhuzamosan folyik. A valódi luxus nem a pompáról és a csillogásról szól. Igyekszünk a környezetpszichológia és az építészet határterületein mozogva minél jobban megismerni az ügyfelünk életmódját, és munkánk eredményeként életüket és életminőségüket magasabb színvonalra emelni.                     </div>
                </div>
            </div>
        </section>
    );
};

export default Interior;
import React from "react";

const Who = () => {

    return (
        <section className="article">
            <div className="row">
                <div className="col-md-8 col-sm-12 col-xs-12 img-cont">
                    <img src="https://innruptive.com/api/storage/uploads/2021/05/19/60a52fd19aaa4Rectangle-36.png" alt="" />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <h1>Kik vagyunk?</h1>
                    <div className="text">
                        Összeszokott tervezőcsapat, aki elkötelezett az átgondolt és időtálló megoldások mellett. Teljes körű tanácsadást, tervezést és ügyintézést szolgáltatunk az előkészítéstől a használatba vételig. Célunk a természet és épített környezet összhangjának megteremtése a legoptimálisabb módon teljes körű odafigyeléssel. Ügyfeleink készséges hozzáállásra számíthatnak tőlünk a projekt elejétől a végéig.
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Who;
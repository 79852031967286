import React, { useState, useEffect, useRef } from "react";

import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as HomeBtn } from "../Svgs/logo_home_button.svg";

const Nav = () => {

    const icon = useRef();
    const [openHamburger, setOpenHamburger] = useState(false);
    const [subParentActive, setSubParentActive] = useState(false);

    let location = useLocation()

    useEffect(() => {
        const urlCond = ["design", "interior", "legal", "kik-vagyunk", "rolunk"];
        const anyPathMatches = urlCond.some(el => location?.pathname?.includes(el))
        anyPathMatches ? setSubParentActive(true) : setSubParentActive(false);
        setOpenHamburger(false)
    }, [location]);

    return (
        <>
            <nav className={openHamburger ? "nav open" + (subParentActive ? " sub" : "") : "nav"} >
                <div className="logo-wrap">
                    <NavLink className="logo" exact to="/">
                        <HomeBtn />
                    </NavLink>
                    <div
                        ref={icon}
                        className={openHamburger ? "menu-btn open" : "menu-btn"}
                        onClick={() => setOpenHamburger(!openHamburger)}
                    >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </div>

                <div className={(openHamburger ? "menuwrap open" + (subParentActive ? " sub-open" : "") : "menuwrap")}>
                    <ul className="menu">
                        <li><NavLink exact to="/projektek" activeClassName="active">Projektek</NavLink></li>
                        <li className={subParentActive ? "wchld open" : "wchld"} ><NavLink to="/rolunk" activeClassName="active">Rólunk</NavLink>
                            <ul>
                                <li><NavLink exact to="/kik-vagyunk" activeClassName="active">Kik vagyunk?</NavLink></li>
                                <li><NavLink exact to="/design" activeClassName="active">Építészeti tervezés</NavLink></li>
                                <li><NavLink exact to="/interior" activeClassName="active">Belsőépítészet</NavLink></li>
                                <li><NavLink exact to="/legal" activeClassName="active">Tanácsadás</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink exact to="/kapcsolat" activeClassName="active">Kapcsolat</NavLink></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Nav;

import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { PageTransition } from '@steveeeie/react-page-transition';

import { fetchApi } from "./Helper/Fetch"

import NotFound from "./Pages/404";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Design from "./Pages/Design";
import Interior from "./Pages/Interior";
import Intro from "./Pages/Intro";
import Legal from "./Pages/Legal";
import Projects from "./Pages/Projects";
import Project from "./Components/Project";
import Who from "./Pages/Who";


const Routes = () => {
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		fetchApi("lakmuhely", setProjects);

		return () => {
			setProjects()
		}
	}, [])

	return (
		<Route
			render={({ location }) => {
				return (
					<PageTransition
						preset="slide"
						transitionKey={location.pathname}
					>
						<Switch location={location}>
							<Route exact path="/" component={Intro} />
							<Route exact path="/rolunk" component={About} />
							<Route exact path="/kik-vagyunk" component={Who} />
							<Route exact path="/kapcsolat" component={Contact} />
							<Route exact path="/design" component={Design} />
							<Route exact path="/interior" component={Interior} />
							<Route exact path="/legal" component={Legal} />
							<Route exact path="/projektek" component={Projects} />
							<Route exact
								path='/projekt/:title'
								render={(props) => (
									<Project {...props} entries={projects} />
								)}
							/>
							<Route component={NotFound} />
						</Switch>
					</PageTransition>
				);
			}}
		/>
	);
};
export default Routes;

import React from "react";

const Contact = () => {
  return (
    <section className="article">
      <table>
        <tbody>
          <tr>
            <td className="name">Gyöngyösi Miklós</td>
            <td> lakmuhely@gmail.com</td>
          </tr>
          <tr>
            <td className="title">okl. építész</td>
            <td>+36 (70) 265-0057</td>
          </tr>
          <tr>
            <td></td>
            <td>4200 Hajdúszoboszló, Tokay u. 50.</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default Contact;
